<template>
    <bs-popup id="master-kurs-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
        <template v-slot:title>Master Kurs</template>
        <template v-slot:content>
            <bs-error-message :errors="errorMessages" />
            <div class="mb-3">
                <label for="startDate" class="form-labeL fw-bold">Tanggal Awal Berlaku</label>
                <div class="mb-3">
                  <view-date v-model="model.startDate"/>
                </div>
            </div>
            <div class="mb-3">
                <label for="endDate" class="form-label fw-bold">Tanggal Akhir Berlaku</label>
                <div class="mb-3">
                  <view-date v-model="model.endDate"/>
                </div>
            </div>
            <div class="mb-3">
                <label for="currencyFromCode" class="form-label fw-bold
                ">Currency Asal</label>
                <bs-plain-text v-model="model.currencyFromCode" />
            </div>
            <div class="mb-3">
                <label for="currencyToCode" class="form-label fw-bold
                ">Currency Tujuan</label>
                <bs-plain-text v-model="model.currencyToCode" />
            </div>
            <div class="mb-3">
                <label for="conversionRate" class="form-label fw-bold
                ">Nilai Kurs</label>
                <bs-plain-text v-model="model.conversionRate" />
            </div>
            <div class="mb-3">
                <label for="supplierName" class="form-label fw-bold
                ">Supplier</label>
                <bs-plain-text v-model="model.supplierName" />
            </div>
            <div class="mb-3">
                <label for="locationName" class="form-label fw-bold
                ">Location</label>
                <bs-plain-text v-model="model.locationName" />
            </div>
            <div class="mb-3">
                <label for="source" class="form-label fw-bold
                ">Sumber</label>
                <bs-plain-text v-model="model.source" />
            </div>


        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-primary" @click="editData"><i class="bi bi-pencil-square"></i> Edit</button>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import MasterKursService from "@/services/master-kurs-service.js";
import ViewDate from "@/components/ViewDate.vue";

export default {
  components: {ViewDate},
    data: function() {
        return {
            id: null,
            model: MasterKursService.default(),
            errorMessages: null
        }
    },
    mounted() {
        let id = this.$route.params.id;
        this.id = id;
        this.loadData();
    },
    methods: {
        async loadData() {
            let response = await MasterKursService.getObject(this.id);
            this.model = response.data;
            if (response.status) 
            {
                this.errorMessages = null;
            }
            else 
            {
                this.errorMessages = response.errorMessages;
            }
        },
        onPopupClosed() {
            this.$router.push('/master-kurs');
        },
        close() {
            this.$refs.form.closePopup();
        },
        editData() 
        {
            this.$refs.form.closePopup();
            this.$router.push(`/master-kurs/edit/${this.id}`);
        }
    }
}
</script>